import React from 'react';
import logo from './assets/logo.png';
import img1 from './assets/1242_2208_1.png';
import img2 from './assets/1242_2208_2.png';
import img3 from './assets/1242_2208_3.png';
import img4 from './assets/1242_2208_4.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Free Math App - Climb as high as you can!</p>

        <div className="app-screenshots">
          <img src={img1} className="app-screenshot" alt="Screenshot 1" />
          <img src={img2} className="app-screenshot" alt="Screenshot 2" />
          <img src={img3} className="app-screenshot" alt="Screenshot 3" />
          <img src={img4} className="app-screenshot" alt="Screenshot 4" />
        </div>

        <p>
          Create your own challenge and invite your friends for a 7 day long
          race to the top with a few taps!
        </p>

        <p>
          Master Everest! Available for free in your store. <b>GOOD LUCK!</b>
        </p>
      </header>
    </div>
  );
}

export default App;
